<template>
  <div class="purchase">
    <div class="is-flex is-justify-content-space-between mb-5">
      <p class="is-size-4 is-size-3-desktop has-text-weight-bold">
        {{ $t("af:purchase.details.title.purchase_details") }}
      </p>
      <b-field>
        <b-button
          v-if="visiblePurchaseCloser"
          :loading="purchaseCloserLoading"
          type="is-danger"
          @click="initiatePurchaseClosing"
        >
          {{ $t("af:purchase.details.button.close_purchase") }}
        </b-button>
      </b-field>
    </div>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-two-thirds-fullhd is-full">
        <div class="card">
          <template v-if="!purchase">
            <b-skeleton width="50%" :animated="true" />
            <b-skeleton width="4rem" height="4rem" />
            <loading-skeleton class="mt-4" />
          </template>
          <template v-else>
            <div
              class="is-flex is-justify-content-space-between is-flex-wrap-wrap mb-3"
            >
              <h3 class="has-text-weight-bold mb-2">
                {{ $t("af:purchase.details.title.purchase_summary") }}
              </h3>
              <purchase-status :status="purchase.status" />
            </div>
            <div class="is-flex">
              <figure class="purchase__logo">
                <image-loader :id="purchase.merchantLogo" max-width="100%" />
              </figure>
              <div
                class="is-flex is-flex-direction-column ml-3 is-justify-content-space-evenly"
              >
                <span class="has-text-weight-medium is-size-5">
                  {{ purchase.merchantName }}
                </span>
                <span class="has-text-grey">
                  {{ purchase.merchantFullName }}
                </span>
              </div>
            </div>
            <h4 class="has-text-weight-bold my-5">
              {{ $t("af:purchase.details.basket_content") }}
            </h4>
            <div
              v-for="(item, index) of purchase.items"
              :key="index"
              class="is-flex is-justify-content-space-between my-3"
            >
              <span>{{ item.name }}</span>
              <span class="has-text-weight-bold">
                {{
                  `${monify(item.price)} ${$t(
                    "af:purchase.details.label.price_unit"
                  )}`
                }}
              </span>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <span class="has-text-weight-medium has-text-grey">
                {{ $t("af:purchase.details.delivery_cost") }}
              </span>
              <span class="has-text-weight-bold">
                {{
                  `${monify(purchase.extraAmount)} ${$t(
                    "af:purchase.details.label.extra_amount_unit"
                  )}`
                }}
              </span>
            </div>
            <div class="is-flex is-justify-content-space-between mt-5">
              <span class="has-text-weight-bold">
                {{ $t("af:purchase.details.purchase_total_amount") }}
              </span>
              <span class="has-text-weight-bold">
                {{
                  `${monify(purchase.totalAmount)} ${$t(
                    "af:purchase.details.label.total_cost_unit"
                  )}`
                }}
              </span>
            </div>
            <div class="purchase__divider" />
            <h5 class="has-text-weight-bold">
              {{ $t("af:purchase.details.important_info") }}
            </h5>
            <p class="has-text-grey">{{ purchase.transactionComment }}</p>
          </template>
        </div>
      </div>
      <div class="column is-one-third-fullhd is-full">
        <div class="card">
          <loading-skeleton v-if="!purchase" />
          <template v-else>
            <p class="has-text-weight-bold is-size-4 mb-4">
              {{ $t("af:purchase.details.financing_mode") }}
            </p>

            <div class="card__content">
              <div class="my-2 is-flex is-justify-content-space-between">
                <span class="has-text-grey">
                  {{ $t("af:purchase.details.self_financed_part") }}
                </span>
                <span class="has-text-weight-medium">{{
                  `${monify(purchase.downPayment)} ${$t(
                    "af:purchase.details.label.down_payment_unit"
                  )}`
                }}</span>
              </div>
              <div class="my-2 is-flex is-justify-content-space-between">
                <span class="has-text-grey">
                  {{ $t("af:purchase.details.loan_amount") }}
                </span>
                <span class="has-text-weight-medium">{{
                  `${monify(purchase.loanAmount)} ${$t(
                    "af:purchase.details.label.loan_amount_unit"
                  )}`
                }}</span>
              </div>
              <div class="my-2 is-flex is-justify-content-space-between">
                <span class="has-text-grey">
                  {{ $t("af:purchase.details.loan_duration") }}
                </span>
                <span class="has-text-weight-medium">
                  {{
                    `${monify(purchase.duration)} ${$t(
                      "af:purchase.details.label.month"
                    )}`
                  }}
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <b-button class="mt-5" @click="routeToPurchaseHistory">
      <span>{{ $t("af:purchase.details.button.back") }}</span>
    </b-button>
  </div>
</template>

<script>
import { monify } from "@/utils/util";
import { mapGetters } from "vuex";
import { ClOSEABLE_PURCHASE_STATUSES } from "@/utils/const";
import i18n from "@/plugins/i18n";

export default {
  name: "Purchase",
  props: {
    purchaseId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    LoadingSkeleton: () => import("@/components/LoadingSkeleton.vue"),
    PurchaseStatus: () => import("@/components/offers/PurchaseStatus.vue"),
    ImageLoader: () => import("@/components/document/ImageLoader.vue"),
  },
  methods: {
    routeToPurchaseHistory() {
      this.$router.push({ name: "UserPurchaseHistory" });
    },
    initiatePurchaseClosing() {
      this.$buefy.dialog.confirm({
        message: i18n.t("af:applications.close_confirm.button.message"),
        cancelText: i18n.t("af:applications.close_confirm.button.cancel"),
        confirmText: i18n.t("af:applications.close_confirm.button.confirm"),
        type: "is-success",
        onConfirm: this.closePurchase,
      });
    },
    async closePurchase() {
      try {
        this.purchaseCloserLoading = true;
        await this.$store.dispatch("purchase/cancel", this.purchaseId);
        await this.$store.dispatch("profile/updateStatus", {
          status: "PRELIMINARY",
          result: "SUCCESS",
        });
        await this.$store.dispatch("purchase/fetchMyPurchases");
        await this.fetch();

        this.$buefy.toast.open({
          message: i18n.t("af:applications.message.successful_close"),
          type: "is-success",
        });

        await this.$store.dispatch("purchase/hasActivePurchase");
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:applications.message.failed_close"),
          type: "is-danger",
        });
      } finally {
        this.purchaseCloserLoading = false;
      }
    },
    async fetch() {
      await this.$store.dispatch("purchase/fetchPurchase", this.purchaseId);
      this.purchase = this.getPurchase(this.purchaseId);
    },
  },
  computed: {
    ...mapGetters({
      getPurchase: "purchase/getPurchase",
    }),
    visiblePurchaseCloser() {
      const status = this.purchase?.status;
      return status && ClOSEABLE_PURCHASE_STATUSES.includes(status);
    },
  },
  data() {
    return {
      monify,
      purchase: null,
      purchaseCloserLoading: false,
    };
  },
  async mounted() {
    await this.fetch();
  },
};
</script>

<style scoped lang="scss">
.purchase {
  &__logo {
    width: 4rem;
    height: 4rem;
    margin: 0;
  }

  &__divider {
    height: 1px;
    background-color: lightgray;
    text-align: center;
    margin: 2.5rem;
  }

  .card {
    border-radius: 24px;
    padding: 2rem;

    &__content {
      overflow-y: auto;
      padding: 2px;
    }
  }
}
</style>
